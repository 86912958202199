.result-fail-wrap {
  position: relative;
  width: 500px;
  height: auto;
  margin: 50px auto 0;
  .question-tip {
    position: absolute;
    right: -75px;
    top: -40px;
    text-align: left;
    width: 200px;
    min-height: 50px;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0 8px 25px -8px rgba(0, 0, 0, 0.35);
    display: none;
  }
  .question-box {
    position: relative;
    &:hover {
      .question-tip {
        display: block !important;
      }
    }
  }
  .txt-tip{
      font-size: 13px;
      margin-top: 15px;
  }
  .question-icon {
    position: absolute;
    top: 10px;
    right: 180px;
    cursor: pointer;
  }
  .helper-img{
      margin-bottom: -7px;
      margin-right: 15px;
  }
  .btn-box {
    margin-top: 60px;
    margin-bottom: 120px;
    .btn {
      width: 230px;
      height: 50px;
      outline: none;
      border: 4px solid #01a27e;
      border-radius: 3px;
      font-size: 16px;
      cursor: pointer;
    }
    .try {
      background-color: #01a27e;
      margin-right: 40px;
      color: #fff;
    }
    .help {
      background-color: #fff;
      color: #01a27e;
    }
  }
  .icon {
    width: 50px;
  }
  .result-text {
    margin-top: 15px;
    color: #0e2c0e;
    font-family: Montserrat-Regular;
    font-size: 16px;
  }
  .tip-box {
    margin-top: 80px;
  }
  .tip1 {
    font-weight: 400;
    font-size: 18px;
    font-family: Montserrat-Regular;
  }
  .tip2 {
    font-weight: 400;
    font-size: 16px;
    font-family: Montserrat-Regular;
    margin-top: 20px;
  }
}
