.home-wrap {
  width: 100%;
  height: auto;
  .home-box {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
  }
  .submit-form {
    position: absolute;
    right: 0;
    top: 612px;
    width: 380px;
    height: 50px;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }
  .box-left {
    width: 800px;
    height: auto;
    .title {
      font-family: Montserrat-Bold;
      height: 22px;
      line-height: 22px;
      color: #0e2c0e;
      font-size: 14px;
      margin-bottom: 16px;
    }
    .title-fu {
      height: 22px;
      line-height: 22px;
      color: #0e2c0e;
      margin-top: -16px;
      margin-bottom: 16px;
      font-size: 12px;
    }
    .country-sct {
      width: 425px;
      height: 38px;
      border-color: #e0e0e0;
    }
    .step-circle {
      position: absolute;
      display: inline-block;
      width: 22px;
      height: 22px;
      border-radius: 15px;
      background-color: #01a27e;
      text-align: center;
      line-height: 22px;
      color: #fff;
      font-size: 14px;
      font-weight: bolder;
    }
    .method-box {
      display: flex;
      .method {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        width: 120px;
        cursor: pointer;
        border: solid 1px #fff;
        margin-bottom: 10px;
      }
      .method-logo {
        height: 52px;
        width: 52px;
        object-fit: scale-down;
        background-color: #eaf5eb;
        border-radius: 52px;
      }
      .method-filter {
        filter: grayscale(100%);
      }
      .method-selected-img {
        position: absolute;
        top: -8px;
        right: 32px;
      }
      .method-text {
        font-family: Montserrat-Regular;
        font-size: 13px;
        text-align: center;
        color: #0e2c0e;
        margin: 10px 5px;
      }
    }
    .step-next {
      min-height: 135px;
      border-left: 1px dashed #c1bfbf;
      margin-left: 11px;
      padding-left: 37px;
    }
    .form-item {
      display: flex;
      margin-bottom: 15px;
    }
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #01a27e !important;
  }
}
.home__loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(188, 189, 189, 0.2);

  .loader {
    position: relative;
    top: 40%;
    left: calc(50% - 30px);
  }
}
.home__notice {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(188, 189, 189, 0.34);
  .home__notice__warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 20%;
    left: 50%;
    transform: translate(-50%);
    height: 300px;
    width: 400px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #dee8f2;

    .home__notice__warningClose {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    .home__notice__warningText {
      font-family: Montserrat-Bold;
      color: #01a27e;
      font-size: 16px;
      margin: 25px 0;
      text-align: center;
    }
    .home__notice__warningButton {
      width: 120px;
      height: 44px;
      background: rgba(20, 117, 223, 0);
      border: 1px solid #01a27e;
      border-radius: 20px;
      font-family: Montserrat-Regular;
      font-size: 16px;
      color: #01a27e;
      cursor: pointer;
    }
  }
}
