.result-box {
  width: 1200px;
  margin: 0 auto;
  min-height: 400px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 40px 0;
  a {
    text-decoration: none;
  }
  .result-left {
    width: 820px;
    text-align: center;
    margin-left: -60px;
  }
  .back-btn {
    display: inline-block;
    width: 230px;
    height: 40px;
    line-height: 40px;
    outline: none;
    border: 4px solid #01a27e;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
    background-color: #01a27e;
    margin-top: 50px;
    color: #fff;
  }
  .result1 {
    margin-top: 127px;
  }
  
  
}
