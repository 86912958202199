.video {
    display: flex;
    flex-direction: column;
    border: 1px solid #dddfe1;
    margin: 20px;
    background-color: #f4f4f4;
    .video__title {
      font-family: Montserrat-Regular;
      font-size: 14px;
      color: #000000;
      padding: 5px;
      margin: 10px 0;
    }
  }
  @media only screen and (max-width: 800px) {
    .video {
      margin: 10px 0;
    }
  }
  