.header-wrap {
  width: 100%;
  height: 75px;
  box-shadow: 0 8px 35px -25px rgba(0, 0, 0, 0.35);
  .header {
    width: 1200px;
    height: 75px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .box-right {
    display: flex;
    align-items: center;
  }
}
.header-lang-container {
  position: relative;
  .header-lang-dropdown {
    display: none;
  }
  .header-lang-text {
    font-family: DMSans-Medium;
    font-size: 14px;
    color: #0c3666;
    line-height: 23px;
    padding: 3px 0;
    margin: 0 5px;
    cursor: pointer;
  }
}
.header-lang-container:hover .header-lang-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #ffffff;
  border: solid 1px #d8dadd;
  top: 22px;
  right: 0;
  z-index: 1000;
  border-radius: 4px;
  cursor: pointer;

  span:hover {
    color: #01a27e;
    transition: background-color 0.2s ease-in;
  }
}
.header-info {
  margin-left: 45px;
  cursor: pointer;
}
