.form-box {
  display: inline-block;
  margin-right: 40px;
}

.form-label {
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
}
.form-input {
  width: 320px;
  height: 38px;
  outline: none;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding-left: 15px;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.00938em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  &:hover {
    border: 1px solid #212121;
  }
  &.active {
    border-color: #63b85b;
  }
}
.form-error {
  color: #f44336;
  font-size: 0.75rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-top: 4px;
}
