.result-success {
  width: 100%;
  text-align: center;
  margin: 50px auto 0;
  .icon {
    width: 50px;
  }
  .result-text {
    margin-top: 15px;
    color: #0e2c0e;
    font-family: Montserrat-Regular;
    font-size: 16px;
  }
  .result-tip {
    font-weight: 400;
    font-size: 14px;
    font-family: Montserrat-Regular;
    margin-top: 20px;
  }
  .tip-box {
    margin-top: 80px;
  }
  .tip1 {
    font-weight: 400;
    font-size: 18px;
    font-family: Montserrat-Regular;
  }
  .tip2 {
    font-weight: 400;
    font-size: 16px;
    font-family: Montserrat-Regular;
    margin-top: 20px;
  }
}
