.box {
    border: 1px solid #dddfe1;
    padding: 10px 20px;
    box-sizing: border-box;
    width: 100%;
    font-family: Montserrat-Regular;
    border-radius: 3px;
    cursor: pointer;
    .box__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .box__title__text {
        font-size: 14px;
        color: #333333;
        margin: 0;
      }
      .box__title__icon {
        padding: 5px;
        margin: 0;
        font-size: 20px;
        color: #6e6e6e;
        
      }
    }
    .box__content {
      font-size: 14px;
      color: #a3b2c2;
      border-top: 1px solid #dddfe1;
      padding: 20px 0;
      margin: 10px 0 0 0;
    }
  }
  