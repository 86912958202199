.dropDown {
    position: absolute;
    top: 10px;
    width: 100%;
    z-index: 200;
    background-color: #ffffff;
    box-shadow: 0 8px 35px -25px rgba(0, 0, 0, 0.35);
    box-sizing: border-box;
    padding: 50px 30px;
    display: flex;
    justify-content: center;
    .dropDown__back {
      position: absolute;
      top: 20px;
      right: 25%;
      cursor: pointer;
      img {
        padding: 5px;
      }
    }
    .dropDown__mainMobile {
      display: none;
    }
    .dropDown__main {
      display: flex;
      justify-content: center;
      .dropDown--section {
        display: flex;
        flex-direction: column;
        font-family: Montserrat-Regular;
        font-size: 14px;
        color: #707070;
        margin: 0 50px;
        cursor: pointer;
        p {
          margin: 8px 0;
        }
        .dropDown--header {
          display: flex;
          align-items: center;
          font-family: Montserrat-Bold;
          font-size: 18px;
          color: #01a27e;
          img {
            margin-right: 8px;
            height: 22px;
          }
        }
      }
    }
    .dropDown__ins {
      width: 100%;
      max-width: 1000px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .dropDown {
      position: fixed;
      display: block;
      top: 100px;
      padding: 20px 30px;
      height: calc(100vh - 100px);
      overflow: scroll;
      z-index: 200;
      .dropDown__back {
        position: initial;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
      }
      .dropDown__main {
        display: none;
      }
      .dropDown__mainMobile {
        display: block;
        .dropDown__mainMobile__content {
          display: flex;
          flex-direction: column;
          .dropDown__mainMobile__title {
            display: flex;
            align-items: center;
            border-bottom: solid 1px #c7cdd1;
            p {
              font-family: Montserrat-Regular;
              color: #0a1f44;
              font-size: 20px;
              margin-left: 10px;
            }
          }
        }
      }
      .dropDown__lang {
        .dropDown__lang__title {
          font-family: Montserrat-Regular;
          color: #0a1f44;
          font-size: 20px;
          border-bottom: solid 1px #c7cdd1;
          padding-bottom: 10px;
        }
      }
    }
  }
  